/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import middleware_9c77d37c from '/home/pbk/pim/frontend/modules/@ergonode/core/src/middleware/privilegeRoutingCheck.global.js';

export default (ctx) => {
	middleware_9c77d37c(ctx);
};
