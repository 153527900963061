/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import route_328daba2 from '/home/pbk/pim/frontend/modules/@ergonode/activity-logs/src/config/routes.js';
import route_53cf46ae from '/home/pbk/pim/frontend/modules/@ergonode/attributes/src/config/routes.js';
import route_3b0bc660 from '/home/pbk/pim/frontend/modules/@ergonode/attribute-groups/src/config/routes.js';
import route_76fe9ead from '/home/pbk/pim/frontend/modules/@ergonode/authentication/src/config/routes.js';
import route_7af7c709 from '/home/pbk/pim/frontend/modules/@ergonode/categories/src/config/routes.js';
import route_ce3f2a42 from '/home/pbk/pim/frontend/modules/@ergonode/category-trees/src/config/routes.js';
import route_4d0b5cb4 from '/home/pbk/pim/frontend/modules/@ergonode/core/src/config/routes.js';
import route_581940ca from '/home/pbk/pim/frontend/modules/@ergonode/dashboard/src/config/routes.js';
import route_b4ae4ed6 from '/home/pbk/pim/frontend/modules/@ergonode/channels/src/config/routes.js';
import route_6450becb from '/home/pbk/pim/frontend/modules/@ergonode/media/src/config/routes.js';
import route_367e7dfa from '/home/pbk/pim/frontend/modules/@ergonode/collections/src/config/routes.js';
import route_2daf2f81 from '/home/pbk/pim/frontend/modules/@ergonode/products/src/config/routes.js';
import route_1be85232 from '/home/pbk/pim/frontend/modules/@ergonode/product-statuses/src/config/routes.js';
import route_462b9898 from '/home/pbk/pim/frontend/modules/@ergonode/product-templates/src/config/routes.js';
import route_462936c5 from '/home/pbk/pim/frontend/modules/@ergonode/segments/src/config/routes.js';
import route_113d5fbc from '/home/pbk/pim/frontend/modules/@ergonode/status-transitions/src/config/routes.js';
import route_77c0dcdc from '/home/pbk/pim/frontend/modules/@ergonode/roles/src/config/routes.js';
import route_8a32c1b2 from '/home/pbk/pim/frontend/modules/@ergonode/users/src/config/routes.js';
import route_180876a0 from '/home/pbk/pim/frontend/modules/@ergonode/import/src/config/routes.js';
import route_0caf7ff4 from '/home/pbk/pim/frontend/modules/@ergonode/workflow/src/config/routes.js';

export default {
	route_328daba2,
	route_53cf46ae,
	route_3b0bc660,
	route_76fe9ead,
	route_7af7c709,
	route_ce3f2a42,
	route_4d0b5cb4,
	route_581940ca,
	route_b4ae4ed6,
	route_6450becb,
	route_367e7dfa,
	route_2daf2f81,
	route_1be85232,
	route_462b9898,
	route_462936c5,
	route_113d5fbc,
	route_77c0dcdc,
	route_8a32c1b2,
	route_180876a0,
	route_0caf7ff4,
};
